import {FunctionalComponent, h} from "preact";
import {route, RouteProps} from "preact-router";
import {useUser} from "../../auth/UserProvider";
import {useEffect} from "preact/compat";
import {useState} from "preact/hooks";
import AuthClientService from "../../auth/AuthClientService";
import {AccountServiceClient} from "../../generated/service/AccountServiceClientPb";
import {TransactionServiceClient} from "../../generated/service/TransactionServiceClientPb";
import {BucketServiceClient} from "../../generated/service/BucketServiceClientPb";

interface ProtectedRouteProps extends RouteProps<any> {
    component: FunctionalComponent<any>;
    fallbackPath: string;
    authClient?: AuthClientService;
    accountServiceClient?: AccountServiceClient;
    txServiceClient?: TransactionServiceClient;
    bucketServiceClient?: BucketServiceClient;
}

const ProtectedRoute: FunctionalComponent<ProtectedRouteProps> = ({component: Component, ...props}) => {
    const {user} = useUser();
    const [isAuthChecked, setIsAuthChecked] = useState(false);

    useEffect(() => {
        if (user && user.isAuthenticated) {
            setIsAuthChecked(true);
        } else {
            setIsAuthChecked(true);
        }
    }, []);

    if (!isAuthChecked) {
        return null;
    }

    if (user && user.isAuthenticated) {
        return <Component {...props} />;
    }

    // route to property path
    route(props.fallbackPath, true);
    return null;
};

export default ProtectedRoute;