import {FunctionalComponent, h} from "preact";
import {BucketServiceClient} from "../../../../../../generated/service/BucketServiceClientPb";
import {useEffect} from "preact/compat";
import {FetchBucketPlansQueryRequest, FetchedBucketPlan} from "../../../../../../generated/query/bucket_plan_pb";
import {useState} from "preact/hooks";
import BucketPlanCard from "../../../BucketPlanCard";
import TitleBackBtn from "../../../../../../components/button/TitleBackBtn";
import {useTranslation} from "react-i18next";
import {formatCurrencyFromCents} from "../../../../../../utils/formatting";

interface FetchBucketPlansProps {
    bucketServiceClient: BucketServiceClient
}

const FetchedBucketPlans: FunctionalComponent<FetchBucketPlansProps> = ({bucketServiceClient}) => {
    const {t} = useTranslation();

    const [hasBucketPlan, setHasBucketPlans] = useState<boolean>(false);
    const [sortedBucketPlans, setSortedBucketPlans] = useState<Array<FetchedBucketPlan>>([]);
    const [error, setError] = useState<string>("");

    useEffect(() => {
        fetchBucketPlans().catch((error) => {
            setError(error.message);
        });
    }, []);

    const fetchBucketPlans = async () => {
        const req = new FetchBucketPlansQueryRequest();
        const resp = await bucketServiceClient.fetchBucketPlans(req);

        if (resp.getTotal() === 0) {
            return;
        }
        setHasBucketPlans(true);

        const plans: FetchedBucketPlan[] = [];
        resp.getPlansMap().forEach((plan: FetchedBucketPlan) => {
            plans.push(plan);
        });
        plans.sort((a: FetchedBucketPlan, b: FetchedBucketPlan) => {
            return a.getAccount().getTitle().localeCompare(b.getAccount().getTitle());
        });

        setSortedBucketPlans(plans);
    };

    const renderBucketPlans = () => {
        if (!hasBucketPlan) {
            return <div>No bucket plans found</div>;
        }

        return sortedBucketPlans.map((plan: FetchedBucketPlan) => {
            const acc = plan.getAccount();

            const lockedAmount = formatCurrencyFromCents(
                plan.getTotallockedamount(),
                plan.getTotallockedamountcurrency(),
                plan.getTotallockedamountdecimals(),
            );

            return (
                <BucketPlanCard
                    planID={plan.getId()}
                    accountID={acc.getId()}
                    accountTitle={acc.getTitle()}
                    formatedTotalLockedAmount={lockedAmount}
                />
            )
        });
    };

    return (
        <div class={"container-lg"}>
            <TitleBackBtn title={t("bucket-plans.headline")} canGoBack={true}/>
            <div class="row g-3">
                {error ? <div class="alert alert-danger" role="alert">{error}</div> : null}
                {renderBucketPlans()}
            </div>
        </div>
    )
}

export default FetchedBucketPlans;