const buckets = {
    en: {
        translation: {
            "bucket-plans.headline": "Bucket Plans (dummy data)",
        }
    },

    de: {
        translation: {
            "bucket-plans.headline": "Bucket-Pläne (Dummy-Daten)",
        }
    },

    ru: {
        translation: {
            "bucket-plans.headline": "Планы Бакет (данные для примера)",
        }
    },
};

export { buckets };