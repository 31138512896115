import {FunctionalComponent, h} from "preact";
import style from "./style.css";
import {useRef, useState} from "preact/hooks";
import {useUser} from "../../../auth/UserProvider";
import {useTranslation} from "react-i18next";
import LanguageSwitcher from "./LanguageSwitcher";
import {useEffect} from "preact/compat";

const ProtectedNavigation: FunctionalComponent = () => {
    const {t} = useTranslation()

    const {user} = useUser();
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const navRef = useRef(null);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    }

    const closeMenu = () => {
        setIsMenuOpen(false);
    }

    const setItemActive = (event) => {
        event.target.classList.add('active');
        event.target.classList.add('bg-dark');
    };

    const unsetItemActive = (event) => {
        event.target.classList.remove('active');
        event.target.classList.remove('bg-dark');
    };

    useEffect(() => {
        const handleTouchOutside = (event) => {
            if (navRef.current && !navRef.current.contains(event.target)) {
                closeMenu();
            }
        };

        document.addEventListener("touchstart", handleTouchOutside);

        return () => {
            document.removeEventListener("touchstart", handleTouchOutside);
        };
    }, []);

    return (
        <nav ref={navRef} className="navbar navbar-dark bg-blue p-2 fixed-top" onMouseLeave={closeMenu}>
            <a className="navbar-brand" href="/"><img src={"/assets/babylon-logo.svg"} alt="Babylon Logo"
                                                      height={30} width={30}/> Babylon</a>
            <button className="navbar-toggler" type="button" onClick={toggleMenu}>
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className={`collapse navbar-collapse text-end ${isMenuOpen ? "show" : ""}`}>
                <ul className="navbar-nav ms-auto">
                    <li className="nav-item ms-auto mt-4">
                        <LanguageSwitcher/>
                    </li>
                    <hr/>
                    <li className="nav-item" onMouseOver={setItemActive} onMouseOut={unsetItemActive}
                        onClick={toggleMenu}>
                        <a
                            className={"nav-link p-2"}
                            href="/accounts">
                            {t("navigation.item.accounts")}
                        </a>
                    </li>
                    <li className="nav-item" onMouseOver={setItemActive} onMouseOut={unsetItemActive}
                        onClick={toggleMenu}>
                        <a
                            className={"nav-link p-2"}
                            href="/buckets">
                            {t("navigation.item.buckets")}
                        </a>
                    </li>
                    <li className="nav-item" onMouseOver={setItemActive} onMouseOut={unsetItemActive}
                        onClick={toggleMenu}>
                        <a
                            className={"nav-link p-2"}
                            href="/tx/purposes">
                            {t("navigation.item.tx_purposes")}
                        </a>
                    </li>
                    <hr/>
                    <li className="nav-item p-2">
                        <span
                            className={style.loggedInAsText}>{t("navigation.text.logged_in_as")} {user.displayName}</span>
                    </li>
                    <li className="nav-item" onMouseOver={setItemActive} onMouseOut={unsetItemActive}
                        onClick={toggleMenu}>
                        <a
                            className={"nav-link p-2"}
                            href="/sign-out"
                        >
                            {t("navigation.item.logout")}
                        </a>
                    </li>
                </ul>
            </div>
        </nav>
    )
};

export default ProtectedNavigation;