import {FunctionComponent, h} from "preact";
import style from "./style.css";
import { useTranslation } from "react-i18next";

interface BucketPlanCardProps {
    planID: string;
    accountID: string;
    accountTitle: string;
    formatedTotalLockedAmount: string;
}

const BucketPlanCard: FunctionComponent<BucketPlanCardProps> = ({planID, accountID, accountTitle, formatedTotalLockedAmount}) => {
    const { t } = useTranslation();

    return (
        <div class="col-12 col-sm-6 col-lg-4">
            <div class={`card ${style.cardBucketPlan}`}>
                <div class="card-body">
                    <h5 class="card-title">{accountTitle}</h5>
                    <p class="card-text">
                        {/*Plan ID: {planID}<br/>*/}
                        {/*Account ID: {accountID}<br/>*/}
                        Locked Amount: {formatedTotalLockedAmount}
                    </p>
                </div>
            </div>
        </div>
    );
};

export default BucketPlanCard;