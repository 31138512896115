import i18n from "../i18n";

/**
 * Formats a currency amount from cents to the user's locale.
 * @param cents The amount in cents.
 * @param currency The currency code.
 * @param decimalPlaces
 * @returns The formatted currency string.
 */
const formatCurrencyFromCents = (
    cents: number,
    currency: string = 'EUR',
    decimalPlaces: number = 2,
): string => {
    const divisor = Math.pow(10, decimalPlaces);
    const normalizedAmount = cents / divisor;

    const isSupportedCurrency = Intl.NumberFormat(i18n.language, { style: 'currency', currency }).resolvedOptions().currency === currency;

    if (isSupportedCurrency) {
        return new Intl.NumberFormat(i18n.language, {
            style: 'currency',
            currency: currency,
            minimumFractionDigits: decimalPlaces,
            maximumFractionDigits: decimalPlaces,
        }).format(normalizedAmount);
    } else {
        return `${normalizedAmount.toFixed(decimalPlaces)} ${currency}`;
    }
};

function formatDate(date: Date): string {
    return new Intl.DateTimeFormat(i18n.language, {
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
    }).format(date);
}

export {formatCurrencyFromCents, formatDate};