import {FunctionalComponent, h} from "preact";
import {BucketServiceClient} from "../../../generated/service/BucketServiceClientPb";
import {useTranslation} from "react-i18next";
import FetchedBucketPlans from "./fetch/bucket/plans";

// BucketsProps is the interface for the props of the Buckets component
interface BucketsProps {
    bucketServiceClient: BucketServiceClient;
}

const Buckets: FunctionalComponent<BucketsProps> = ({bucketServiceClient}) => {
    const {t} = useTranslation();

    return (
        <div class={"container-lg"}>
            <FetchedBucketPlans bucketServiceClient={bucketServiceClient}/>
        </div>
    )
}

export default Buckets;