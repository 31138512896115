/**
 * @fileoverview gRPC-Web generated client stub for service
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v3.21.12
// source: service/bucket.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as command_bucket_plan_pb from '../command/bucket_plan_pb'; // proto import: "command/bucket_plan.proto"
import * as query_bucket_plan_pb from '../query/bucket_plan_pb'; // proto import: "query/bucket_plan.proto"


export class BucketServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorAssignBucketPlan = new grpcWeb.MethodDescriptor(
    '/service.BucketService/AssignBucketPlan',
    grpcWeb.MethodType.UNARY,
    command_bucket_plan_pb.AssignBucketPlanCommandRequest,
    command_bucket_plan_pb.AssignBucketPlanCommandResponse,
    (request: command_bucket_plan_pb.AssignBucketPlanCommandRequest) => {
      return request.serializeBinary();
    },
    command_bucket_plan_pb.AssignBucketPlanCommandResponse.deserializeBinary
  );

  assignBucketPlan(
    request: command_bucket_plan_pb.AssignBucketPlanCommandRequest,
    metadata?: grpcWeb.Metadata | null): Promise<command_bucket_plan_pb.AssignBucketPlanCommandResponse>;

  assignBucketPlan(
    request: command_bucket_plan_pb.AssignBucketPlanCommandRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: command_bucket_plan_pb.AssignBucketPlanCommandResponse) => void): grpcWeb.ClientReadableStream<command_bucket_plan_pb.AssignBucketPlanCommandResponse>;

  assignBucketPlan(
    request: command_bucket_plan_pb.AssignBucketPlanCommandRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: command_bucket_plan_pb.AssignBucketPlanCommandResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/service.BucketService/AssignBucketPlan',
        request,
        metadata || {},
        this.methodDescriptorAssignBucketPlan,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/service.BucketService/AssignBucketPlan',
    request,
    metadata || {},
    this.methodDescriptorAssignBucketPlan);
  }

  methodDescriptorFetchBucketPlans = new grpcWeb.MethodDescriptor(
    '/service.BucketService/FetchBucketPlans',
    grpcWeb.MethodType.UNARY,
    query_bucket_plan_pb.FetchBucketPlansQueryRequest,
    query_bucket_plan_pb.FetchBucketPlansQueryResponse,
    (request: query_bucket_plan_pb.FetchBucketPlansQueryRequest) => {
      return request.serializeBinary();
    },
    query_bucket_plan_pb.FetchBucketPlansQueryResponse.deserializeBinary
  );

  fetchBucketPlans(
    request: query_bucket_plan_pb.FetchBucketPlansQueryRequest,
    metadata?: grpcWeb.Metadata | null): Promise<query_bucket_plan_pb.FetchBucketPlansQueryResponse>;

  fetchBucketPlans(
    request: query_bucket_plan_pb.FetchBucketPlansQueryRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: query_bucket_plan_pb.FetchBucketPlansQueryResponse) => void): grpcWeb.ClientReadableStream<query_bucket_plan_pb.FetchBucketPlansQueryResponse>;

  fetchBucketPlans(
    request: query_bucket_plan_pb.FetchBucketPlansQueryRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: query_bucket_plan_pb.FetchBucketPlansQueryResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/service.BucketService/FetchBucketPlans',
        request,
        metadata || {},
        this.methodDescriptorFetchBucketPlans,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/service.BucketService/FetchBucketPlans',
    request,
    metadata || {},
    this.methodDescriptorFetchBucketPlans);
  }

}

