import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

// translations
import {navigation} from "./components/header/navigation/i18n";
import {home} from "./routes/home/i18n";
import {login} from "./routes/login/i18n";
import {logout} from "./routes/protected/logout/i18n";
import {notFound} from "./routes/not-found/i18n";
import {accountsOverview} from "./routes/protected/accounts/i18n";
import {accountAddOneTimeExpense} from "./routes/protected/accounts/add/one-time-expense/i18n";
import {accountAddOneTimeIncome} from "./routes/protected/accounts/add/one-time-income/i18n";
import {accountAddOneTimeTransfer} from "./routes/protected/accounts/add/one-time-transfer/i18n";
import {accountFetchDetails} from "./routes/protected/accounts/fetch/details/i18n";
import {transactionPurposes} from "./routes/protected/tx-purposes/i18n";
import {buckets} from "./routes/protected/buckets/i18n";

const i18nResources = {};
const i18nModules = [
    navigation,
    home,
    login,
    logout,
    notFound,
    accountsOverview,
    accountAddOneTimeExpense,
    accountAddOneTimeIncome,
    accountAddOneTimeTransfer,
    accountFetchDetails,
    transactionPurposes,
    buckets,
];

i18nModules.forEach(module => {
    Object.keys(module).forEach(lang => {
        i18nResources[lang] = {
            translation: {
                ...i18nResources[lang]?.translation,
                ...module[lang]?.translation
            }
        };
    });
});

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: i18nResources,
        debug: false,
        fallbackLng: "en",
        supportedLngs: ['en', 'de', 'ru'],
        nonExplicitSupportedLngs: true, // Treat 'en-US' as 'en'
        detection: {
            order: ['localStorage', 'navigator'],
            caches: ['localStorage'],
            lookupLocalStorage: 'i18nextLng',
        },
        interpolation: {
            escapeValue: false,
        }
    })
    .catch(console.error);

export default i18n;